import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type LoadingState = {
  loading: boolean;
};

export const initialState: LoadingState = {
  loading: false,
};

const slice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const { setLoading } = slice.actions;
export default slice.reducer;
