import { Box, BoxProps } from "@chakra-ui/react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

type Props = {
  icon: IconDefinition;
  label?: String;
  reverse?: boolean;
};

export const Icon: React.FC<Props & BoxProps> = ({ icon, label, reverse = false, ...props }) => {
  return (
    <Box
      alignItems={"center"}
      display={"inline-flex"}
      flexDirection={reverse ? "row-reverse" : "row"}
      gap={1}
      {...props}
    >
      <FontAwesomeIcon fixedWidth icon={icon} />
      {label && <Box>{label}</Box>}
    </Box>
  );
};
