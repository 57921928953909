import { useRouter } from "next/router";
import React from "react";

export const useBuildId = () => {
  const router = useRouter();

  React.useEffect(() => {
    if (process.env.NODE_ENV != "production") return;
    const buildId = JSON.parse(document.querySelector("#__NEXT_DATA__")?.textContent || "{}").buildId;

    if (!buildId) return;

    const shouldReload = () => {
      const request = new XMLHttpRequest();
      request.open("HEAD", `/_next/static/${buildId}/_buildManifest.js`, false);
      request.setRequestHeader("Pragma", "no-cache");
      request.setRequestHeader("Cache-Control", "no-cache");
      request.setRequestHeader("If-Modified-Since", "Thu, 01 Jun 1970 00:00:00 GMT");
      request.send(null);

      if (request.status === 404) document.location.reload();
    };

    router.events.on("routeChangeComplete", shouldReload);

    return () => {
      router.events.off("routeChangeComplete", shouldReload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
