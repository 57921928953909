import { estate } from "~libs/i18n/ja/model/estate";

const aasmStates = [
  { label: "初回提案", value: "first_offer" },
  { label: "詳細提案", value: "detail_offer" },
  { label: "現地視察中", value: "on_site" },
  { label: "試算中", value: "estimating" },
  { label: "交渉中", value: "in_negotiation" },
  { label: "契約前申請", value: "application" },
  { label: "契約準備", value: "contract_preparation" },
  { label: "契約完了", value: "done" },
];

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const deal = {
  modelName: "取引",
  id: "取引ID",
  aasmHumanState: "ステータス",
  aasmState: "ステータス",
  createdAt: "提案日時",
  adminUser: "提案ユーザー",
  closeReason: "終了理由",
  closeUser: "終了したユーザー",
  closedAt: "終了日時",
  closed: "終了",
  contractedOn: "契約締結日",
  contract: "契約",
  reminderTimes: "契約リマインダーメール送信回数",
  reminder: "契約リマインダーメール",
  lastReminderOn: "最終送信日",
  checked: "確認",
  checkedOptions: [
    { label: "確認済み", value: "true" },
    { label: "未確認", value: "false" },
  ],
  closedOptions: [
    { label: "終了した取引", value: "true" },
    { label: "終了していない取引", value: "false" },
  ],
  aasmStates,
  states: [
    { value: "unread", label: "未読メッセージ" },
    { value: "unchecked", label: "未確認", adminOnly: true },
    ...aasmStates,
    { value: "closed", label: "終了" },
    { value: "all", label: "すべて" },
  ],
  estateArea: estate.area,
  estatePrice: estate.price,
  estatePrefecture: estate.prefecture,
};
