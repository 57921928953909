/* eslint-disable sort-keys-fix/sort-keys-fix */
export const estate = {
  modelName: "土地",
  id: "物件ID",
  aasmState: "ステータス",
  reinsId: "レインズ物件ID",
  reinsNo: "レインズ物件番号",
  address: "住所",
  addressReading: "所在地 読み",
  title: "タイトル",
  comment: "コメント",
  registeredOn: "販売登録日",
  revisedOn: "販売更新日",
  estateType: "物件種目",
  estateTypeOptions: "売地,借地権,底地権",
  auction: "オークション",
  adType: "広告転載区分",
  adTypeOptions: "広告可,一部可(インターネット),一部可(チラシ・新聞広告),広告可(但し要連絡),不可",
  dealType: "取引態様",
  dealTypeOptions: "売主,代理,専任,一般,その他",
  dealtOn: "媒介契約年月日",
  dealStatus: "取引状況",
  dealStatusOptions: "公開中,書面による購入申し込みあり,売主都合で一時紹介停止中",
  dealComment: "取引状況の補足",
  estateCompany: "不動産会社",
  staffName: "物件問い合わせ先担当者",
  staffTel: "物件担当者電話番号",
  staffEmail: "物件担当者メールアドレス",
  staffMemo: "自社管理欄",
  price: "価格",
  pricePerMeter: "㎡単価",
  pricePerTsubo: "坪単価",
  area: "土地面積",
  areaMeasurementType: "面積計測方式",
  areaMeasurementTypeOptions: "公簿,実測",
  setBack: "セットバック区分",
  setBackOptions: "無,有,済",
  privateLoadContribution: "私道負担有無",
  privateLoadArea: "私道面積",
  prefecture: "都道府県名",
  addressLine1: "所在地名1",
  addressLine2: "所在地名2",
  addressLine3: "所在地名3",
  addressLine3Display: "所在地名3表示フラグ",
  addressLine3DisplayOptions: "表示する,表示しない",
  otherAddressLine: "その他所在地表示",
  transportations: "交通手段",
  otherTransportation: "その他交通手段",
  minutesForOtherTransportation: "交通（分）",
  metersForOtherTransportation: "交通（m）",
  noLumpSum: "その他一時金なし",
  lumpSum1Name: "その他一時金名称1",
  lumpSum1Price: "その他一時金名称1（金額）",
  lumpSum2Name: "その他一時金名称2",
  lumpSum2Price: "その他一時金名称2（金額）",
  status: "現況",
  statusOptions: "更地,上物有",
  movable: "引渡時期",
  movableOptions: "即時,相談,期日指定,予定",
  movableOn: "引渡年月",
  movableEml: "引渡年月(上旬・中旬・下旬)",
  movableEmlOptions: "上旬,中旬,下旬",
  rewardType: "報酬形態",
  rewardTypeOptions: "分かれ,当方不払,当方片手数,代理折半,相談",
  reward: "報酬",
  chargePercentage: "手数料割合率",
  charge: "手数料",
  cityPlanning: "都市計画",
  cityPlanningOptions: "市街,調整,非線引き,域外,準都市",
  registryLandType: "登記簿地目",
  registryLandTypeOptions: "宅地,田,畑,山林,雑種,他",
  currentLandType: "現況地目",
  currentLandTypeOptions: "宅地,田,畑,山林,雑種,他",
  useDistrictType: "用途地域",
  useDistrictTypeOptions: "一低,二中,二住,近商,商業,準工,工業,工専,二低,一中,一住,準住,田園,無指定",
  optimalUseType: "最適用途",
  optimalUseTypeOptions:
    "住宅用地,マンション用地,ビル用地,店舗用地,工業用地,配送センタ用地,営業所用地,保養所用地,その他用地,事務所用地,別荘用地,倉庫用地,資材置場用地,家庭菜園用地,アパート用地,社宅社員寮用地,病院診療所用地,畑・農地用地,事業用地,駐車場用地",
  districtType: "地域地区",
  districtTypeOptions: "防火,準防火,高度,高度利用,風致,文教,その他",
  buildingCoveragePercentage: "建ぺい率",
  floorAreaPercentage: "容積率",
  floorAreaPercentageLimit: "容積率の制限内容",
  limitOfLaw: "その他法令上の制限",
  reconstruction: "再建築",
  legalNotification: "国土法届出",
  legalNotificationOptions: "要,中,不要",
  leasehold: "借地権種類",
  leaseholdOptions: "旧法地上,旧法賃借,普通地上,定期地上,普通賃借,定期賃借",
  leasePrice: "借地料",
  leaseExpiredOn: "借地期限",
  terrain: "地勢",
  terrainOptions: "平坦,高台,低地,ひな段,傾斜地,その他",
  constructionCondition: "建設条件",
  constructionConditionOptions: "有,無",
  approachStatus: "接道状況",
  approachStatusOptions: "一方,角地,三方,四方,二方",
  approachPavement: "接道舗装",
  approachPavementOptions: "有,無",
  equipments: "設備・条件",
  otherEquipments: "設備(フリースペース)",
  condition: "条件(フリースペース)",
  note1: "備考1",
  note2: "備考2",
  note3: "備考3",
  note4: "備考4",
  latitude: "緯度",
  longitude: "経度",
  internalStatus: "内部ステータス",
};

export const estateApproach = {
  approachType: "接道種別",
  approachTypeOptions: "公道,私道",
  facingLength: "接道接面",
  preferredPosition: "接道位置指定",
  preferredPositionOptions: "有,無",
  direction: "接道方角",
  directionOptions: "北,北東,東,南東,南,南西,西,北西",
  width: "接道幅員",
};

export const estateTransportation = {
  railwayLineName: "沿線名",
  stationName: "駅名",
  minutesWalkFromStation: "駅より徒歩（分）",
  metersWalkFromStation: "駅より徒歩（m）",
  carFromStation: "駅より車",
  busFromStation: "駅よりバス",
  minutesFromBusStop: "バス停より徒歩（分）",
  metersFromBusStop: "バス停より徒歩（ｍ）",
  busLineName: "バス路線名",
  busStopName: "バス停名称",
};

export const estateSurrounding = {
  name: "周辺環境",
  meter: "距離",
  by: "時間（手段）",
  byOptions: "徒歩,車",
  minute: "時間（分）",
};
