import { chatMessage } from "~libs/i18n/ja/model/chatMessage";
import { deal } from "~libs/i18n/ja/model/deal";
import { document } from "~libs/i18n/ja/model/document";
import { documentRevision } from "~libs/i18n/ja/model/documentRevision";
import { estate, estateApproach, estateSurrounding, estateTransportation } from "~libs/i18n/ja/model/estate";
import { estateCompany } from "~libs/i18n/ja/model/estateCompany";
import { group } from "~libs/i18n/ja/model/group";
import { memo } from "~libs/i18n/ja/model/memo";
import { adminUser, clientUser, user } from "~libs/i18n/ja/model/user";

const models = {
  adminUser,
  chatMessage,
  clientUser,
  common: {
    createdAt: "登録日時",
    updatedAt: "更新日時",
  },
  deal,
  document,
  documentRevision,
  estate,
  estateApproach,
  estateCompany,
  estateSurrounding,
  estateTransportation,
  group,
  memo,
  user,
};

const pages = {
  admin: {
    deals: {
      title: models.deal.modelName,
    },
    estateCompanies: {
      new: { title: "登録" },
      title: models.estateCompany.modelName,
    },
    estates: {
      import: { title: "インポート" },
      title: models.estate.modelName,
    },
    groups: {
      title: models.group.modelName,
    },
    title: "ダッシュボード",
  },
  client: {
    deals: { title: models.deal.modelName },
    title: "ダッシュボード",
  },

  debug: {
    login: { title: "デバッグログイン" },
  },
};

export const ja = {
  format: {
    date: {
      default: "{{- date, yyyy/MM/dd}}",
      yearMonth: "{{- date, yyyy年MM月}}",
    },
    datetime: {
      default: "{{- datetime, yyyy/MM/dd HH:mm}}",
    },
    kiloMeter: "{{kiloMeter, kiloMeter}}",
    meter: "{{meter, meter}}",
    minute: "{{minute, minute}}",
    number: "{{number, number}}",
    percentage: "{{percentage, percentage}}",
    squareMeter: "{{squareMeter, squareMeter}}",
    yen: "{{yen, yen}}",
  },
  models,
  pages,
  prefectures:
    "北海道,青森県,岩手県,宮城県,秋田県,山形県,福島県,茨城県,栃木県,群馬県,埼玉県,千葉県,東京都,神奈川県,新潟県,山梨県,長野県,富山県,石川県,福井県,岐阜県,静岡県,愛知県,三重県,滋賀県,京都府,大阪府,兵庫県,奈良県,和歌山県,鳥取県,島根県,岡山県,広島県,山口県,徳島県,香川県,愛媛県,高知県,福岡県,佐賀県,長崎県,熊本県,大分県,宮崎県,鹿児島県,沖縄県",
  site: { name: "SOLARiT" },
};
