import { List, Stack } from "@chakra-ui/layout";

Stack.defaultProps = { spacing: 6 };

List.defaultProps = {
  marginInlineStart: "1.2rem",
};

export const components = {
  Button: {
    baseStyle: {
      icon: {
        marginLeft: "auto",
      },
      minHeight: "44px",
    },
    variants: {
      card: {
        _hover: {
          backgroundColor: "gray.100",
        },
        alignItems: "center",
        background: "white",
        borderColor: "gray.300",
        boxShadow: "base",
        display: "flex",
        fontWeight: "normal",
        height: "inherit",
        justifyContent: "flex-start",
        lineHeight: "inherit",
        minHeight: "44px",
        padding: "16px",
        textAlign: "left",
      },
      outline: {
        backgroundColor: "white",
      },
    },
  },
  Checkbox: {
    defaultProps: {
      colorScheme: "brand",
    },
    variants: {
      button: {
        container: {
          _checked: {
            _hover: {
              bg: "brand.500",
            },
            bg: "brand.500",
            color: "white",
            fontWeight: "bold",
          },
          _hover: {
            opacity: 0.8,
          },
          bg: "white",
          borderColor: "gray.200",
          borderWidth: 1,
          px: 4,
          py: 2,
          rounded: "md",
          w: "full",
        },
        control: {
          _checked: {
            borderColor: "gray.200",
            borderWidth: 1,
          },
          _focusVisible: {
            boxShadow: "none",
          },
        },
      },
    },
  },
  FormLabel: {
    baseStyle: {
      fontSize: "sm",
      fontWeight: "bold",
    },
  },
  Heading: {
    baseStyle: {
      wordBreak: "break-all",
    },
    sizes: {
      h1: {
        fontSize: "var(--chakra-fontSizes-h1)",
      },
      h2: {
        fontSize: "var(--chakra-fontSizes-h2)",
      },
      h3: {
        fontSize: "var(--chakra-fontSizes-h3)",
      },
      h4: {
        fontSize: "var(--chakra-fontSizes-h4)",
      },
      h5: {
        fontSize: "var(--chakra-fontSizes-h5)",
      },
      h6: {
        fontSize: "var(--chakra-fontSizes-h6)",
      },
    },
  },
  Input: {
    defaultProps: {
      size: "lg",
    },
    variants: {
      outline: {
        field: {
          bg: "white",
        },
      },
    },
  },
  Link: {
    variants: {
      text: {
        _hover: {
          color: "blue.700",
        },
        color: "blue.500",
        textDecoration: "underline",
      },
    },
  },
  List: {
    baseStyle: {
      item: {
        paddingLeft: "0.5rem",
      },
    },
    variants: {
      asterisk: {
        item: {
          listStyleType: "'※'",
        },
      },
      brackets: {
        item: {
          _before: {
            content: `"("counter(cnt)")"`,
            left: "-0.8rem",
            position: "absolute",
          },
          counterIncrement: "cnt",
          paddingLeft: "1rem",
          position: "relative",
        },
      },
      disc: {
        container: {
          marginInlineStart: "1.2rem",
        },
        item: {
          listStyleType: "disc",
          paddingLeft: 0,
        },
      },
    },
  },
  Menu: {
    baseStyle: {
      list: {
        zIndex: "popover",
      },
    },
  },
  Modal: {
    baseStyle: {
      dialog: {
        maxH: "calc(100vh - 20px)",
      },
      dialogContainer: {
        padding: "10px",
      },
    },
  },
  Radio: {
    baseStyle: {
      cursor: "pointer",
    },
  },
  Select: {
    defaultProps: {
      size: "lg",
    },
  },
  Table: {
    baseStyle: {
      th: {
        textTransform: "none",
        whiteSpace: "nowrap",
      },
    },
  },
  Tabs: {
    variants: {
      search: {
        tab: {
          _selected: {
            bg: "white",
            mb: "-1px",
          },
          border: "1px solid",
          borderColor: "inherit",
          borderTopRadius: "lg",
        },
        tablist: {
          mb: "-1px",
        },
      },
    },
  },
};
