import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { compact, find, pick, reject } from "lodash";
import Router from "next/router";
import qs from "qs";

import { SearchAttributes, SearchFilterAttributes } from "~generated/graphql";

export type SearchState = {
  attributes: SearchAttributes;
  draft: Pick<SearchAttributes, "filters" | "keyword">;
  initialized: boolean;
  isOpen: boolean;
  syncUrl: boolean;
};

export const initialState: SearchState = {
  attributes: {},
  draft: {},
  initialized: false,
  isOpen: false,
  syncUrl: false,
};

const slice = createSlice({
  initialState,
  name: "search",
  reducers: {
    cleanupSearch() {
      return initialState;
    },

    clearCondition(state) {
      if (state.syncUrl) {
        Router.push({ query: undefined }, undefined, { shallow: true });
      }

      return { ...initialState, initialized: true };
    },

    commit(state) {
      const keyword = state.draft.keyword?.trim().replaceAll(/\s+/g, " ");
      const normalized = { ...state.draft, keyword: keyword || undefined };
      if (state.syncUrl) {
        Router.push({ query: qs.stringify(normalized) }, undefined, { shallow: true });
      }
      return { ...state, attributes: { ...normalized }, draft: { ...normalized }, isOpen: false };
    },

    initializeSearch(_state, { payload }: PayloadAction<{ syncUrl?: boolean }>) {
      const syncUrl = payload?.syncUrl || false;

      let attributes: SearchAttributes = {};
      if (syncUrl) {
        const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
        attributes = qs.parse(location.search, { ignoreQueryPrefix: true });

        if (parsed.page) attributes.page = Number(parsed.page);
        if (parsed.perPage) attributes.perPage = Number(parsed.perPage);
        if (parsed.sortDesc) attributes.sortDesc = parsed.sortDesc == "true";
      }

      return {
        ...initialState,
        attributes,
        draft: pick(attributes, "keyword", "filters"),
        initialized: true,
        syncUrl,
      };
    },

    resetPageAndSort(state) {
      delete state.attributes.page;
      delete state.attributes.sortBy;
      delete state.attributes.sortDesc;
      if (state.syncUrl) {
        Router.push({ query: qs.stringify(state.attributes) }, undefined, { shallow: true });
      }
    },

    setFilter(state, { payload }: PayloadAction<SearchFilterAttributes>) {
      if (compact(payload.values).length === 0) {
        state.draft.filters = reject(state.draft.filters, { field: payload.field });
        return;
      }

      const filter = find(state.draft.filters, { field: payload.field });

      if (filter) {
        filter.values = payload.values;
      } else {
        state.draft.filters ||= [];
        state.draft.filters.push(payload);
      }
    },

    setIsOpen(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },

    setKeyword(state, action: PayloadAction<string>) {
      state.draft.keyword = action.payload;
    },

    setPage(state, action: PayloadAction<number>) {
      state.attributes.page = action.payload;

      if (state.syncUrl) {
        Router.push({ query: qs.stringify(state.attributes) }, undefined, { shallow: true });
      }
    },

    setSort(state, action: PayloadAction<{ sortBy: string; sortDesc: boolean }>) {
      state.attributes.sortBy = action.payload.sortBy;
      state.attributes.sortDesc = action.payload.sortDesc;
      delete state.attributes.page;
      if (state.syncUrl) {
        Router.push({ query: qs.stringify(state.attributes) }, undefined, { shallow: true });
      }
    },
  },
});

export const {
  cleanupSearch,
  clearCondition,
  commit,
  initializeSearch,
  resetPageAndSort,
  setFilter,
  setIsOpen,
  setKeyword,
  setPage,
  setSort,
} = slice.actions;
export default slice.reducer;
