import { useRouter } from "next/router";

export const useRouterUtil = () => {
  const { pathname } = useRouter();

  return {
    isAdmin: pathname.startsWith("/admin"),
    isClient: pathname.startsWith("/client"),
    isDebug: pathname.startsWith("/debug"),
    isPublic: pathname.startsWith("/public"),
  };
};
