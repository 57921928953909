import { useApolloClient } from "@apollo/client";
import { FirebaseError, getApps, initializeApp } from "firebase/app";
import { signOut, getAuth, onAuthStateChanged } from "firebase/auth";
import nProgress from "nprogress";
import React from "react";
import { useDispatch } from "react-redux";

import { setFirebaseLoggedIn, storeLogout } from "~/store/auth";

export const config = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
};

export function useFirebase() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (getApps().length < 1) {
      initializeApp(config);
    }

    return onAuthStateChanged(getAuth(), async (firebaseUser) => {
      dispatch(setFirebaseLoggedIn(!!firebaseUser));
    });
  }, [dispatch]);
}

export function useLogout() {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const logout = async () => {
    await signOut(getAuth());
    await client.clearStore();
    dispatch(storeLogout());
    nProgress.done();
  };

  return logout;
}

export const errorToMessage = (
  e: FirebaseError,
  { updatePassword }: { updatePassword: boolean } = { updatePassword: false }
) => {
  switch (e.code) {
    case "auth/cancelled-popup-request":
    case "auth/popup-closed-by-user":
      return null;
    case "auth/email-already-in-use":
      return "このメールアドレスは使用されています";
    case "auth/invalid-email":
      return "メールアドレスの形式が正しくありません";
    case "auth/user-disabled":
      return "サービスの利用が停止されています";
    case "auth/user-not-found":
      return "メールアドレスまたはパスワードが違います";
    case "auth/weak-password":
      return "パスワードは6文字以上にしてください";
    case "auth/wrong-password":
      return updatePassword ? "パスワードが違います" : "メールアドレスまたはパスワードが違います";
    case "auth/popup-blocked":
      return "認証ポップアップがブロックされました。ポップアップブロックをご利用の場合は設定を解除してください";
    case "auth/operation-not-supported-in-this-environment":
    case "auth/auth-domain-config-required":
    case "auth/operation-not-allowed":
    case "auth/unauthorized-domain":
      return "現在この認証方法はご利用頂けません";
    case "auth/requires-recent-login":
      return "認証の有効期限が切れています";
    default:
      return e.code;
  }
};
