import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { UserFragment } from "~generated/graphql";

export type AuthState = {
  debug: boolean;
  firebaseLoggedIn: boolean | undefined;
  user: UserFragment | undefined;
};

const debugUserKey = "debugUser";

export const initialState: AuthState = {
  debug: false,
  firebaseLoggedIn: undefined,
  user: undefined,
};

const slice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    loadDebugUser(state) {
      try {
        const debugUser = JSON.parse(localStorage.getItem(debugUserKey) || "");
        state.user = debugUser;
        state.firebaseLoggedIn = true;
        state.debug = true;
      } catch {
        // do nothing
      }
    },

    setDebugUser(state, action: PayloadAction<UserFragment>) {
      state.user = action.payload;
      state.firebaseLoggedIn = true;
      state.debug = true;
      localStorage.setItem("debugUser", JSON.stringify(state.user));
    },

    setFirebaseLoggedIn(state, action: PayloadAction<boolean>) {
      if (state.debug) return;

      state.firebaseLoggedIn = action.payload;
    },

    setUser(state, action: PayloadAction<UserFragment>) {
      state.user = action.payload;
    },

    storeLogout(state) {
      state.firebaseLoggedIn = false;
      state.user = undefined;
      state.debug = false;
      localStorage.removeItem(debugUserKey);
    },
  },
});

export const { loadDebugUser, setDebugUser, setFirebaseLoggedIn, setUser, storeLogout } = slice.actions;
export default slice.reducer;
