import { ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
import { useTranslation } from "react-i18next";
import { Provider as ReduxProvider, useDispatch } from "react-redux";
import { RecoilRoot } from "recoil";

import "~libs/font-awesome";
import "~libs/i18n";
import "~libs/react-datepicker";
import "~libs/yup";
import "~/styles/application.scss";

import { useFirebase, useLogout } from "~/libs/firebase";
import { store, useAppSelector } from "~/store";
import { loadDebugUser, setUser } from "~/store/auth";
import { clearError } from "~/store/error";
import theme from "~/theme";
import { pagesPath } from "~generated/$path";
import { useMeQuery } from "~generated/graphql";
import { apolloClient } from "~graphql/client";
import { useBuildId } from "~hooks/useBuildId";
import { useConfirm } from "~hooks/useConfirm";
import { Layout } from "~layouts/Layout";
import { LoadingModal } from "~layouts/LoadingModal";
import { ProgressBar } from "~layouts/ProgressBar";
import { debugEnabled } from "~libs/utils";

function MyApp(props: AppProps) {
  const { t } = useTranslation();
  useBuildId();

  return (
    <>
      <Head>
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta content="noindex" name="robots" />
        <title>{t("site.name")}</title>
      </Head>
      <RecoilRoot>
        <ReduxProvider store={store}>
          <ChakraProvider theme={theme}>
            <ApolloProvider client={apolloClient}>
              <Page {...props} />
            </ApolloProvider>
          </ChakraProvider>
        </ReduxProvider>
      </RecoilRoot>
    </>
  );
}
export default MyApp;

const Page = ({ Component, pageProps }: AppProps) => {
  useFirebase();

  const confirm = useConfirm();
  const logout = useLogout();
  const dispatch = useDispatch();
  const router = useRouter();

  const firebaseLoggedIn = useAppSelector((state) => state.auth.firebaseLoggedIn);
  const debug = useAppSelector((state) => state.auth.debug);
  const user = useAppSelector((state) => state.auth.user);
  const error = useAppSelector((state) => state.error);

  const { data } = useMeQuery({ skip: !firebaseLoggedIn || debug });

  React.useEffect(() => {
    if (debugEnabled) dispatch(loadDebugUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!data?.me) return;

    dispatch(setUser(data.me));
  }, [data?.me, dispatch, user]);

  React.useEffect(() => {
    if (!error.errorMessage) return;

    (async () => {
      await confirm(error.errorMessage, { okOnly: true, title: "エラー" });
      if (error.unauthorized) await logout();
      if (error.notFound) router.replace(pagesPath.$url());

      dispatch(clearError());
    })();
  }, [confirm, dispatch, error, logout, router]);

  return (
    <>
      <Layout>
        <ProgressBar />
        <Component {...pageProps} />
      </Layout>

      <LoadingModal />
    </>
  );
};
