export const group = {
  aasmState: "ステータス",
  aasmStateOptions: [
    { label: "active", value: "active" },
    { label: "inactive", value: "inactive" },
  ],
  admin: "管理グループ",
  id: "グループID",
  modelName: "グループ",
  name: "グループ名",
  usersCount: "ユーザー数",
};
