import { FormLabel, FormLabelProps } from "@chakra-ui/react";
import React from "react";

import { RequireLabel, RequireLabelProps } from "~form/RequireLabel";

type Props = RequireLabelProps;

export const MyFormLabel: React.FC<Props & FormLabelProps> = ({
  children,
  isEitherRequired,
  isOptional,
  isRequired,
  ...props
}) => {
  return (
    <FormLabel alignItems="center" display="flex" {...props}>
      <RequireLabel
        isEitherRequired={isEitherRequired}
        isOptional={isOptional}
        isRequired={isRequired}
        mr={1}
        {...props}
      />
      {children}
    </FormLabel>
  );
};
