import { Box, BoxProps } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React from "react";

import { useAppSelector } from "~/store";
import { Loading } from "~data-display/Loading";
import { pagesPath } from "~generated/$path";
import { useRouterUtil } from "~hooks/useRouterUtil";
import { Header } from "~layouts/Header";
import { debugEnabled } from "~libs/utils";
import { Login } from "~standalone/Login";

type Props = {};

export const Layout: React.FC<Props & BoxProps> = ({ children }) => {
  const router = useRouter();
  const firebaseLoggedIn = useAppSelector((state) => state.auth.firebaseLoggedIn);
  const user = useAppSelector((state) => state.auth.user);
  const { isAdmin, isClient, isDebug, isPublic } = useRouterUtil();

  React.useEffect(() => {
    if (isPublic) return;
    if (isDebug) {
      if (debugEnabled) return;
      router.replace(pagesPath.$url());
    }
    if (firebaseLoggedIn === undefined || user === undefined) return;

    if (isClient && user.admin) {
      router.replace(pagesPath.admin.$url());
      return;
    }

    if (isAdmin && user.client) {
      router.replace(pagesPath.client.$url());
      return;
    }
  }, [firebaseLoggedIn, isAdmin, isClient, isDebug, isPublic, router, user]);

  if (isDebug) {
    if (!debugEnabled) return <Loading />;
    return <>{children}</>;
  } else {
    if (isPublic) return <>{children}</>;
    if (firebaseLoggedIn === undefined) return <Loading />;
    if (firebaseLoggedIn === false) return <Login />;
    if (user === undefined) return <Loading />;

    if (isClient && !user.client) return <Loading />;
    if (isAdmin && !user.admin) return <Loading />;
  }

  return (
    <Box>
      <Header />
      <Box p={4} pt={36}>
        {children}
      </Box>
    </Box>
  );
};
