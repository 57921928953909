export const styles = {
  global: {
    "*, *::before, ::after": {
      borderColor: "gray.300",
    },
    "*:focus": {
      boxShadow: "none !important",
    },
    body: {
      bg: "secondaryGray.300",
    },
    img: {
      display: "inline-block",
    },
  },
};
