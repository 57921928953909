import { Box, BoxProps, Center, Modal, ModalBody, ModalContent, ModalOverlay, Spinner } from "@chakra-ui/react";
import React from "react";

import { useAppSelector } from "~/store";

type Props = {};

export const LoadingModal: React.FC<Props & BoxProps> = ({ ...props }) => {
  const loading = useAppSelector((state) => state.loading.loading);

  return (
    <Box {...props}>
      <Modal isCentered isOpen={loading} onClose={() => null} size="xs">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Center>
              <Box py={8} textAlign="center">
                <Spinner size="xl" />
                <Box fontWeight="bold" mt={8}>
                  Loading...
                </Box>
              </Box>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
