import { configureStore } from "@reduxjs/toolkit";
import { useSelector as useReduxSelector, TypedUseSelectorHook, useDispatch } from "react-redux";
import { combineReducers } from "redux";

import auth from "~/store/auth";
import error from "~/store/error";
import loading from "~/store/loading";
import search from "~/store/search";

const rootReducer = combineReducers({
  auth,
  error,
  loading,
  search,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const store = configureStore({
  reducer: rootReducer,
});

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
