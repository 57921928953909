import type { Query as Query0 } from '../pages/public/register'

export const pagesPath = {
  "account": {
    $url: (url?: { hash?: string }) => ({ pathname: '/account' as const, hash: url?.hash })
  },
  "admin": {
    "deals": {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/admin/deals/[id]' as const, query: { id }, hash: url?.hash })
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/admin/deals' as const, hash: url?.hash })
    },
    "estate_companies": {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/admin/estate-companies/[id]' as const, query: { id }, hash: url?.hash })
      }),
      "new": {
        $url: (url?: { hash?: string }) => ({ pathname: '/admin/estate-companies/new' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/admin/estate-companies' as const, hash: url?.hash })
    },
    "estates": {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/admin/estates/[id]' as const, query: { id }, hash: url?.hash })
      }),
      "import": {
        $url: (url?: { hash?: string }) => ({ pathname: '/admin/estates/import' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/admin/estates' as const, hash: url?.hash })
    },
    "groups": {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/admin/groups/[id]' as const, query: { id }, hash: url?.hash })
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/admin/groups' as const, hash: url?.hash })
    },
    "pdf_editor": {
      $url: (url?: { hash?: string }) => ({ pathname: '/admin/pdf-editor' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/admin' as const, hash: url?.hash })
  },
  "client": {
    "deals": {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/client/deals/[id]' as const, query: { id }, hash: url?.hash })
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/client/deals' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/client' as const, hash: url?.hash })
  },
  "deals": {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/deals/[id]' as const, query: { id }, hash: url?.hash })
    })
  },
  "debug": {
    "login": {
      $url: (url?: { hash?: string }) => ({ pathname: '/debug/login' as const, hash: url?.hash })
    }
  },
  "public": {
    "password_reset": {
      $url: (url?: { hash?: string }) => ({ pathname: '/public/password-reset' as const, hash: url?.hash })
    },
    "register": {
      $url: (url: { query: Query0, hash?: string }) => ({ pathname: '/public/register' as const, query: url.query, hash: url.hash })
    }
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash })
}

export type PagesPath = typeof pagesPath
