export const user = {
  email: "メールアドレス",
  id: "ユーザーID",
  manager: "マネージャー",
};

export const adminUser = {
  modelName: "管理者",
  name: "管理者名",
  ...user,
};

export const clientUser = {
  modelName: "クライアント",
  name: "クライアント名",
  ...user,
};
