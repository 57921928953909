import { Box, BoxProps, Flex, forwardRef, LinkBox, LinkOverlay, useBoolean } from "@chakra-ui/react";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import React from "react";

import { ChakraButton } from "~alias/ChakraButton";
import { Icon } from "~data-display/Icon";

export type CardProps = {
  accordion?: boolean;
  bodyProps?: BoxProps;
  leftTitle?: React.ReactNode;
  noBody?: boolean;
  rightTitle?: React.ReactNode;
  title?: React.ReactNode;
} & Omit<BoxProps, "title">;

export const Card: React.FC<CardProps> = forwardRef(
  ({ accordion = false, bodyProps, children, leftTitle, noBody = false, rightTitle, title, ...props }, ref) => {
    const [isOpen, setOpen] = useBoolean();

    const renderTitle = () => {
      if (!leftTitle && !title) return null;

      return (
        <LinkBox
          _hover={{ bg: accordion ? "gray.100" : "white" }}
          alignItems={"baseline"}
          cursor={accordion ? "pointer" : "initial"}
          display="flex"
          gap={2}
          p={4}
        >
          {leftTitle}
          {title && (
            <Box fontSize={"xl"} fontWeight="bold">
              {title}
            </Box>
          )}
          {rightTitle && <Box ml="auto">{rightTitle}</Box>}

          {accordion && (
            <LinkOverlay ml="auto" onClick={setOpen.toggle}>
              <ChakraButton variant={"link"}>
                <Icon icon={isOpen ? faAngleDown : faAngleUp} />
              </ChakraButton>
            </LinkOverlay>
          )}
        </LinkBox>
      );
    };

    return (
      <Flex bg="white" flexDir={"column"} ref={ref} rounded="2xl" {...props}>
        {renderTitle()}

        {children && (
          <Box
            display={!accordion || isOpen ? "block" : "none"}
            p={noBody ? 0 : 4}
            paddingTop={title || noBody ? 0 : 4}
            {...bodyProps}
          >
            {children}
          </Box>
        )}
      </Flex>
    );
  }
);

Card.displayName = "Card";
