import { Center } from "@chakra-ui/react";
import React from "react";
import { RingLoader } from "react-spinners";

export const Loading: React.VFC = () => {
  return (
    <Center flexGrow={1} h="80vh">
      <RingLoader />
    </Center>
  );
};
