import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";
import { createUploadLink } from "apollo-upload-client";
import { getAuth } from "firebase/auth";

import { store } from "~/store";
import { setError } from "~/store/error";

const httpLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
}) as unknown as ApolloLink;

const authLink = setContext(async (_, { headers }) => {
  const state = store.getState();

  const additional: { [k: string]: any } = {};
  if (!state.auth.debug) {
    const firebaseUser = getAuth().currentUser;
    const token = await firebaseUser?.getIdToken();
    if (token) additional.authorization = `Bearer ${token}`;
  } else {
    additional["X-User-ID"] = state.auth.user?.id;
  }
  return {
    headers: {
      ...headers,
      ...additional,
    },
  };
});

const isValidationError = (e: any) => {
  return !!e.graphQLErrors?.[0]?.extensions?.validation;
};

const isUnauthorizedError = (e: any) => {
  return !!e.graphQLErrors?.[0]?.extensions?.unauthorized;
};

const isNotFoundError = (e: any) => {
  return !!e.graphQLErrors?.[0]?.extensions?.not_found;
};

const errorLink = onError((error) => {
  Sentry.withScope((scope) => {
    scope.setExtra("operationName", error.operation.operationName);
    scope.setExtra("query", error.operation.query.loc?.source?.body?.trim());
    scope.setExtra("variables", JSON.stringify(error.operation.variables, null, "  "));

    if (error.networkError) {
      scope.setExtra("networkError", JSON.stringify(error.networkError, null, "  "));
      store.dispatch(setError({ errorMessage: "通信エラーが発生しました" }));
      Sentry.captureMessage(`Network Error: ${error.operation.operationName}`);
    } else if (error.graphQLErrors) {
      scope.setExtra("graphQLErrors", JSON.stringify(error.graphQLErrors, null, "  "));
      scope.setExtra("operation", JSON.stringify(error.operation, null, "  "));
      scope.setExtra("response", JSON.stringify(error.response, null, "  "));

      if (isValidationError(error)) {
        store.dispatch(setError({ errorMessage: error.graphQLErrors?.[0]?.message || "バリデーションエラー" }));
      } else if (isUnauthorizedError(error)) {
        store.dispatch(setError({ errorMessage: error.graphQLErrors[0].message, unauthorized: true }));
      } else if (isNotFoundError(error)) {
        store.dispatch(setError({ errorMessage: "データが見つからない、またはアクセスできません", notFound: true }));
      } else {
        store.dispatch(setError({ errorMessage: error.graphQLErrors?.[0]?.message || "システムエラーが発生しました" }));
        Sentry.captureMessage(`GraphQL Error: ${error.operation.operationName}`);
      }
    }
  });
});

export const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      notifyOnNetworkStatusChange: true,
    },
  },
  link: ApolloLink.from([authLink, errorLink, httpLink]),
});
