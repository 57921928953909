import { UrlObject } from "url";

import { Box, BoxProps } from "@chakra-ui/react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";
import React from "react";

import { NextLink } from "~alias/NextLink";

type Props = {
  exact?: boolean;
  href?: UrlObject;
  icon: IconDefinition;
  label: string;
  onClick?: () => void;
};

export const HeaderMenuItem: React.FC<Props & BoxProps> = ({ exact = false, href, icon, label, onClick, ...props }) => {
  const router = useRouter();

  const render = () => {
    const pathname = String(href?.pathname);
    const active = exact ? router.pathname === pathname : router.pathname.startsWith(pathname);

    return (
      <Box alignItems="center" as="a" cursor="pointer" display="block" onClick={onClick} {...props}>
        <Box
          _hover={{ bg: active ? "" : "gray.100" }}
          alignItems="center"
          bg={active ? "brand.400" : "white"}
          color={active ? "white" : "gray.600"}
          display="flex"
          fontSize="sm"
          fontWeight={"bold"}
          justifyContent={"center"}
          px={3}
          py={1}
          rounded="xl"
          whiteSpace={"nowrap"}
        >
          <Box color={active ? "white" : "gray.600"} fontSize="lg" mr={3}>
            <FontAwesomeIcon fixedWidth icon={icon} />
          </Box>
          {label}
        </Box>
      </Box>
    );
  };

  if (!href) return render();

  return (
    href && (
      <NextLink href={href} passHref>
        {render()}
      </NextLink>
    )
  );
};
