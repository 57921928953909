import defaultTheme from "@chakra-ui/theme";

const brand = defaultTheme.colors.orange;

const pink = {
  "100": "#f5e1e6",
  "200": "#edc9d2",
  "300": "#e3abb8",
  "400": "#d8889b",
  "50": "#fbf3f5",
  "500": "#cc637c",
  "600": "#b1566a",
  "700": "#974959",
  "800": "#783a43",
  "900": "#5c2d32",
};

const secondaryGray = {
  100: "#E0E5F2",
  200: "#E1E9F8",
  300: "#F4F7FE",
  400: "#E9EDF7",
  500: "#8F9BBA",
  600: "#A3AED0",
  700: "#707EAE",
  800: "#707EAE",
  900: "#1B2559",
};

export const colors = {
  body: defaultTheme.colors.gray["800"],
  brand,
  emphasis: pink["500"],
  error: defaultTheme.colors.red["500"],
  muted: defaultTheme.colors.gray["500"],
  pink,
  primary: brand["400"],
  secondaryGray,
};
