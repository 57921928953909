import { BoxProps, Center, Flex, Heading } from "@chakra-ui/react";
import Image from "next/image";
import React from "react";

import logo from "@/public/logo.png";

export const PublicLayout: React.FC<BoxProps> = ({ children }) => {
  return (
    <Center flexDirection={"column"} h={"100vh"}>
      <Flex flexDir={"column"} gap={8} maxW="container.sm" w="full">
        <Heading m="auto" textAlign={"center"} w={40}>
          <Image alt="Solar" layout="responsive" src={logo} />
        </Heading>
        {children}
      </Flex>
    </Center>
  );
};
