import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ErrorState = {
  errorMessage: String | null;
  notFound?: boolean;
  unauthorized?: boolean;
};

export const initialState: ErrorState = {
  errorMessage: null,
  notFound: false,
  unauthorized: false,
};

const slice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    clearError() {
      return initialState;
    },

    setError(_state, action: PayloadAction<ErrorState>) {
      return action.payload;
    },
  },
});

export const { clearError, setError } = slice.actions;
export default slice.reducer;
