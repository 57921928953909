import { Box, Flex, HStack } from "@chakra-ui/react";
import { faMapLocationDot, faCommentsDollar, faUsers, faBuilding, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useTranslation } from "react-i18next";

import logo from "@/public/logo.png";
import { NextLink } from "~/components/alias/NextLink";
import { ChakraLink } from "~alias/ChakraLink";
import { NextImage } from "~alias/NextImage";
import { pagesPath } from "~generated/$path";
import { useRouterUtil } from "~hooks/useRouterUtil";
import { AccountMenu } from "~layouts/AccountMenu";
import { HeaderMenuItem } from "~layouts/HeaderMenuItem";

export const Header = () => {
  const { t } = useTranslation();
  const { isAdmin, isClient } = useRouterUtil();

  const renderHeaderMenu = () => {
    if (isAdmin) {
      return (
        <>
          <HeaderMenuItem
            href={pagesPath.admin.estates.$url()}
            icon={faMapLocationDot}
            label={t("pages.admin.estates.title")}
          />
          <HeaderMenuItem
            href={pagesPath.admin.deals.$url()}
            icon={faCommentsDollar}
            label={t("pages.admin.deals.title")}
          />
          <HeaderMenuItem
            href={pagesPath.admin.estate_companies.$url()}
            icon={faBuilding}
            label={t("pages.admin.estateCompanies.title")}
          />
          <HeaderMenuItem href={pagesPath.admin.groups.$url()} icon={faUsers} label={t("pages.admin.groups.title")} />
          <HeaderMenuItem href={pagesPath.admin.pdf_editor.$url()} icon={faFilePdf} label={"PDFエディター"} />
        </>
      );
    }

    if (isClient) {
      return (
        <>
          <HeaderMenuItem
            href={pagesPath.client.deals.$url()}
            icon={faCommentsDollar}
            label={t("pages.client.deals.title")}
          />
        </>
      );
    }

    return null;
  };

  return (
    <Flex
      alignItems="center"
      as="header"
      bg="white"
      boxShadow={"base"}
      h={12}
      left={0}
      pos="fixed"
      px={4}
      py={0}
      right={0}
      top={0}
      zIndex="banner"
    >
      <NextLink href={pagesPath.$url()} passHref>
        <ChakraLink display={"block"} flexShrink={0} height={10}>
          <NextImage height={40} src={logo} width={136} />
        </ChakraLink>
      </NextLink>

      <Box color="brand.500" lineHeight={1.1} ml={4} whiteSpace="nowrap">
        <Box fontSize="sm" sx={{ textAlignLast: "justify" }}>
          株式会社Colorsの
        </Box>
        <Box fontSize="xs" sx={{ textAlignLast: "justify" }}>
          仲介物件管理システム
        </Box>
      </Box>

      <Flex className="hide-scrollbar" gap={2} mx={4} overflowX={"scroll"}>
        {renderHeaderMenu()}
      </Flex>

      <HStack ml="auto" pl={4}>
        <AccountMenu />
      </HStack>
    </Flex>
  );
};
