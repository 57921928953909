import { Box, IconButton, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Spinner } from "@chakra-ui/react";
import { faSignOut, faUser } from "@fortawesome/free-solid-svg-icons";
import { useRouter } from "next/router";
import React from "react";

import { useAppSelector } from "~/store";
import { Icon } from "~data-display/Icon";
import { pagesPath } from "~generated/$path";
import { useConfirm } from "~hooks/useConfirm";
import { useMyToast } from "~hooks/useMyToast";
import { useLogout } from "~libs/firebase";

export const AccountMenu: React.FC = () => {
  const logout = useLogout();
  const user = useAppSelector((state) => state.auth.user);
  const confirm = useConfirm();
  const router = useRouter();
  const toast = useMyToast();

  const onLogoutClick = async () => {
    if (await confirm("ログアウトしてもよろしいですか")) {
      logout();
      router.replace(pagesPath.$url());
      toast({ title: "ログアウトしました" });
    }
  };

  if (!user) return null;

  return (
    <Box>
      <Menu>
        <MenuButton
          as={IconButton}
          backgroundColor="primary"
          color="white"
          h={8}
          icon={<Icon icon={faUser} />}
          minH={8}
          minW={8}
          rounded="full"
          w={8}
        />
        <MenuList w={80}>
          <>
            <Box color="muted" ml={4}>
              <Box fontSize={"xs"}>{user.email}</Box>
              <Box fontWeight={"bold"}>
                {user.name} ({user.group.name})
              </Box>
              {user.manager && <Box fontSize={"sm"}>マネージャー</Box>}
            </Box>
            <MenuDivider />
            <MenuItem icon={<Icon icon={faSignOut} />} onClick={onLogoutClick}>
              ログアウト
            </MenuItem>
          </>
          {user === undefined && <Spinner />}
        </MenuList>
      </Menu>
    </Box>
  );
};
