import { format as dateFormat, isValid } from "date-fns";
import i18next, { use } from "i18next";
import numeral from "numeral";
import { initReactI18next } from "react-i18next";

import { ja } from "~libs/i18n/ja";

const units: { [k: string]: { format: string; unit: string } } = {
  kiloMeter: { format: "0,0", unit: "km" },
  meter: { format: "0,0", unit: "m" },
  minute: { format: "0,0", unit: "分" },
  number: { format: "0,0", unit: "" },
  percentage: { format: "0,0", unit: "%" },
  squareMeter: { format: "0,0.[000]", unit: "㎡" },
  yen: { format: "0,0", unit: "円" },
};

use(initReactI18next).init({
  interpolation: {
    format: (value, format) => {
      if (format && format in units) {
        return value !== null && value !== undefined
          ? numeral(value).format(units[format].format) + units[format].unit
          : "";
      }

      if (value instanceof Date && isValid(value)) {
        return value && format ? dateFormat(value, format) : "";
      }
      return value;
    },
  },
  lng: "ja",
  resources: {
    ja: {
      translation: ja,
    },
  },
});

export default i18next;
